/* eslint-disable max-lines */

import { CustomerFacingCancellationReason, SingleLanguageLocalization } from './types';

/**
 * ------------- SPANISH ------------
 *| |_______________________________
 *| ||MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM|
 *| ||MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM|
 *| ||      __.__                   |
 *| ||     I o o I                  |
 *| ||     I.d d.I                  |
 *| ||     I x x I                  |
 *| ||                              |
 *| ||MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM|
 *| ||MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM|
 *| |'""""""""""""""""""""""""""""""'
 */
export const es: SingleLanguageLocalization = {
  /**
   * Product
   */
  product: {
    location: 'Ubicación',
    noLocationSelected: 'Sin ubicación seleccionada',
    invalidBookingId: 'Reserva no encontrada - ID de reserva no válido',
    confirm: 'Confirmar',
    Booking: 'Reserva',
    booking: 'reserva',
    yourBooking: 'Tu reserva',
    Giftcard: 'Tarjeta de regalo',
    giftcard: 'tarjeta de regalo',
    campaign: 'campaña',
    voucher: 'cupón',
    refund: 'Cupón',
    membership: 'membresía',
    'membership-campaign': 'campaña',
    yourGiftcard: 'Tu tarjeta de regalo',
    configHeader: 'Barcos y duración',
    durationHeading: 'Barcos y duración',
    dateAndTime: 'Fecha y hora de salida',
    yourInformation: 'Tu información',
    extras: 'Extras',
    extrasAndRules: 'Extras & Reglas',
    finalize: 'Finalizar pedido',
    completeBooking:
      'Tu reserva casi está confirmada.\n\nCompleta tu reserva haciendo clic en el botón a la derecha.',
    completeBookingMobile:
      'Tu reserva casi está confirmada.\n\nCompleta tu reserva a través del resumen del pedido en la parte inferior de la pantalla.',
    numberOfUnits: 'Núm. de barcos',
    personsPerUnit: '8 pers. por barco',
    boat: 'barco',
    boats: 'barcos',
    person: 'persona',
    people: 'personas',
    hour: 'hora',
    hours: 'horas',
    minutes: 'minutos',
    hourAbbr: 'h',
    minuteAbbr: 'm',
    chooseLocation: 'Seleccionar ubicación',
    currentBooking: 'Reserva actual',
    show: 'Mostrar',
    hide: 'Ocultar',
    currentbooking: 'reserva actual',
    currentgiftcard: 'tarjeta de regalo actual',
    configureGiftcard: 'Configurar tu tarjeta de regalo',
    durationInHours: 'Duración',
    acceptTerms: 'Aceptar términos y condiciones',
    subscribeToNewsletter:
      'Me gustaría recibir el boletín de GoBoat con inspiración marítima e información sobre eventos',
    yes: 'Sí',
    no: 'No',
    subscribeToNewsletterShort: 'Suscribirse al boletín',
    next: 'Siguiente',
    prev: 'Anterior',
    page: 'Página',
    addToOrder: 'Agregar al pedido',
    added: 'Añadido',
    haveAVoucher: '¿Tienes un cupón?',
    voucherCode: 'Código del cupón',
    applyVoucher: 'Aplicar cupón',
    voucherApplied: 'Se ha aplicado un cupón',
    toBePaid: 'Por pagar',
    confirmPayment: 'Confirmar pago',
    confirmBooking: 'Confirmar reserva',
    confirmGiftcard: 'Confirmar tarjeta de regalo',
    confirmMembership: 'Confirmar membresía',
    cancelBooking: 'Cancelar reserva',
    cancelTextVoucherRefund1:
      '¿Estás seguro de que deseas cancelar tu reserva?\n\nRecibirás un correo electrónico con un cupón por el valor de ',
    cancelTextVoucherRefund2: '\nEl cupón caduca en ',
    cancelTextVoucherRefund3: ' meses.',
    cancelTextRefund1:
      '\nSi deseas un reembolso, debes ponerte en contacto con el servicio al cliente.',
    cancelTextRefund2: '\nComo el tiempo de salida es de menos de ',
    cancelTextRefund3: ' horas, no podrás recibir un reembolso.',
    cancelTextNoRefund1:
      '¿Estás seguro de que deseas cancelar tu reserva?\n\nComo el tiempo de salida es de menos de ',
    cancelTextNoRefund2:
      ' horas o no se ha recibido el pago de la reserva, tu reserva se cancelará y no recibirás un reembolso.',
    canceledBooking: '¡Lamentamos verte ir!',
    canceledTextVoucherRefund: `Se ha emitido un cupón y se le enviará por correo electrónico en breve.

Entendemos que los planes pueden cambiar, y nos gustaría saber por qué tuvo que cancelar su reserva con nosotros. Sus comentarios son invaluables y nos ayudan a mejorar nuestros servicios.`,

    canceledTextNoRefund:
      'Entendemos que los planes pueden cambiar, y nos gustaría saber por qué tuvo que cancelar su reserva con nosotros. Sus comentarios son invaluables y nos ayudan a mejorar nuestros servicios.',

    canceledTextInitial:
      'La reserva se ha cancelado, haz clic en el botón de abajo si deseas reservar una nueva experiencia.',
    canceledButtonTextInitial: 'Reservar nueva experiencia',
    cancellationFeedbackPrompt:
      'Por favor, háganos saber el motivo de su cancelación haciendo clic en una de las opciones a continuación:',
    cancellationThankYou:
      'Gracias por sus comentarios. Agradecemos su tiempo y esperamos poder atenderle mejor en el futuro.',
    pleaseWait: 'Por favor espera...',
    positiveBalance1: 'Tu reserva tiene un saldo positivo.',
    positiveBalance2Refund:
      'Puedes usar este saldo para comprar extras o puedes solicitar un reembolso poniéndote en contacto con el servicio al cliente.',
    positiveBalance2VoucherRefund:
      'Puedes usar este saldo para comprar extras o puedes solicitar un cupón poniéndote en contacto con el servicio al cliente.',
    positiveBalance3:
      'También puedes solicitar el reembolso por adelantado enviando un correo electrónico a ',
    neutralBalance: 'Tu pedido está equilibrado, no se requiere ningún pago adicional.',
    refundEmailSubject: 'Reembolso - ID de reserva: ',
    priceForSailing: 'Precio por navegación',
    priceForExtras: 'Precio por extras',
    priceForBooking: 'Precio por reserva',
    cancellationWarranty: 'Garantía de cancelación',
    cancellationWarrantyHeading: '¿No estás seguro de poder asistir?',
    cancellationWarrantyText1:
      'Al agregar nuestra garantía de cancelación, podrás cambiar la fecha de tu reserva o cancelarla hasta ',
    cancellationWarrantyText2: ' horas antes de la salida.',
    thankYou: 'Gracias',
    captain: 'capitán',
    bookingCompleted: 'Se ha completado tu reserva. Esperamos verte en los muelles.',
    practicalInfo1: 'Asegúrate de estar en la base de alquiler ',
    practicalInfo2: ' al menos 15 minutos antes de la hora de salida.',
    confirmationEmailSentTo: 'Se ha enviado un correo electrónico de confirmación a',
    learnMore: 'Más información',
    cancel: 'Cancelar',
    fetching: 'Cargando',
    loading: 'Cargando',
    selectDateToViewTimeslots: 'Selecciona una fecha para ver los horarios disponibles',
    yourInformationBookingHeld:
      'Una vez que hayas completado tu información, se reservará tu hora de salida durante 10 minutos.',
    noOpenDays: 'No hay días disponibles en el mes seleccionado',
    defaultPaymentSuccessText:
      'Gracias por tu reserva. Esperamos recibirte a bordo.\n\nRecuerda llegar 15 minutos antes de la hora de salida.\n\nSi tienes alguna pregunta antes de tu navegación, ponte en contacto con nuestro equipo.',
    OR: 'O',
    left: 'quedan',
    goToCheckout: 'Ir a la caja',
    bookingHeldHeading: 'Tu hora de salida está reservada',
    bookingHeldText:
      'Después de 10 minutos, no podemos garantizar que tu hora de salida todavía esté disponible.',
    bookingHeldTextConfirmBooking: 'Por favor, continúa para confirmar la reserva.',
    allRightsReserved: 'Todos los derechos reservados',
    cookieSettings: 'Configuración de cookies',
    custom: 'Custom',
    amount: 'Amount',
    customAmount: 'Custom amount',
    selectPickUpLocation: 'Elija dónde quiere embarcar',
    selectDropOffLocation: 'Elija dónde quiere desembarcar',
    select: 'Seleccionar',
    selected: 'Seleccion',
    includedInPrice: 'Incluido en el precio',
    viewOnMap: 'Ver en el mapa',
    pickupLocation: 'Recoger',
    dropoffLocation: 'Dejar',
    from: 'De',
    enterYourPaymentDetails: 'Introduce tus datos de pago',
    roomForUpTo: 'Espacio para hasta',
    addDesiredExtras: 'Añade tus extras deseados a continuación.',
    whereWouldYouLikeToSail: '¿Dónde te gustaría navegar?',
    howManyBoats: '¿Cuántos barcos?',
    howManyHours: '¿Cuántas horas?',
    customerFormHeading: '¿A nombre de quién confirmamos tu reserva?',
    customerFormText:
      'El email es para la confirmación, el número de teléfono es para la seguridad.',
  },

  /**
   * Giftcard
   */
  giftcard: {
    recipientInformation: 'Información del destinatario',
    optional: 'Opcional',
    defaultPaymentSuccessText:
      'Gracias por comprar una tarjeta de regalo para una experiencia única y sostenible en GoBoat.',
  },

  /**
   * Membership
   */
  membership: {
    membership: 'membresía',
    yourMembership: 'Tu membresía',
    defaultPaymentSuccessText: 'Gracias por inscribirte en la membresía GoBoat Plus.',
  },

  /**
   * Sauna
   */
  sauna: {
    duration: 'Duración',
    dateAndTime: 'Fecha y hora',
    selectDateToViewTimeslots: 'Selecciona una fecha para ver los horarios disponibles',
    defaultPaymentSuccessText:
      'Gracias por la reserva. Esperamos recibirte.\n\nRecuerda presentarte 15 minutos antes de tu hora reservada.\n\nSi tienes alguna pregunta sobre tu reserva, no dudes en contactarnos.',
    boat: 'persona',
    boats: 'personas',
    numberOfUnits: 'Núm. de personas',
    personsPerUnit: 'Capacidad: 8 personas',
    durationHeading: 'Personas y duración',
  },

  /**
   * Customer
   */
  customer: {
    firstname: 'Nombre',
    lastname: 'Apellido',
    phone: 'Número de teléfono',
    email: 'Correo electrónico',
    birthDate: 'Fecha de nacimiento',
    birthDatePattern: '(YYYY-MM-DD)',
    zip: 'Código Postal',
    province: 'Provincia',
    streetAddress: 'Dirección',
    city: 'Ciudad',
    pin: 'Italian Fiscal Code',
  },

  /**
   * Recipient
   */
  recipient: {
    name: 'Nombre',
  },

  /**
   * Price
   */
  price: {
    price: 'Precio',
    allIncluded: 'Precio todo incluido',
    paid: 'Pagado',
    toBePaid: 'Por pagar',
    toBeRefunded: 'Por reembolsar',
    confirm: 'Confirmar',
  },

  /**
   * Lookup
   */
  lookup: {
    noBookingTitle: 'Hmm, no podemos encontrar esa reserva...',
    noBookingContent: 'Parece que no tenemos ninguna reserva en el sistema con el ID:',
    noRequestTitle: '¿Buscas tu reserva?',
    noRequestContent: 'No parece que hayas solicitado ninguna reserva en absoluto.',
    giefId:
      'Por favor, proporciona el ID de reserva del correo electrónico de confirmación que recibiste de nosotros.',
    getBooking: 'Obtener reserva',
  },

  /**
   * Error
   */
  error: {
    NoShopId: 'El ID de la tienda no es válido.',
    NoBoatCount: 'La reserva no contiene barcos.',
    NoAvailableTimeslots: 'No hay horarios disponibles.',
    SlotNoLongerAvailable:
      'El horario seleccionado no está disponible. Por favor, selecciona un horario diferente.',
    BookingNotCreated:
      'Ha ocurrido un error. La reserva no se ha creado. Por favor, actualiza la página e inténtalo de nuevo.',
    SelectStartTime: 'Por favor, selecciona una hora de inicio para tu reserva.',
    WeekdayNotValid: 'El día de la semana seleccionado no es válido para este cupón.',
    VoucherAlreadyAdded: 'Ya se ha añadido un cupón a esta reserva.',
    VoucherNotValidForTimeslot:
      'El cupón no es válido en el horario de salida seleccionado.',
    PercentVoucherAlreadyUsed: 'Ya se ha añadido un cupón a esta reserva.',
    VoucherDepleted: 'El cupón ya no es válido.',
    VoucherLocationInvalid: 'El cupón no es válido para la ubicación seleccionada.',
    VoucherWeekdayInvalid: 'El cupón no es válido para el día de la semana seleccionado.',
    VoucherDateInvalid: 'El cupón no es válido para la fecha seleccionada.',
    VoucherInvalid: 'El cupón no es válido.',
    VoucherNotFound: 'El cupón no se ha encontrado.',
    VoucherValidForInvalid: 'El cupón no es válido.',
    InvalidVoucherCombination: 'Estos bonos no se pueden utilizar juntos.',
    VoucherAlreadyApplied: 'El bono ya ha sido aplicado a la reserva.',
    NotValidPayment:
      'La solicitud de pago no es válida; la transacción no se ha completado.',
    ShopNotOpen: 'La tienda no está abierta en la fecha seleccionada.',
    ShopNoLongerOpen:
      'La tienda ya no tiene horarios de apertura válidos en la fecha seleccionada.',
    ShopHasNoPriceModels: 'La tienda no tiene modelos de precios.',
    NoShopsFound: 'No se encontraron ubicaciones',
    UnknownError:
      'Se ha producido un error desconocido. Por favor, actualiza la página e inténtalo de nuevo.',
    PriceMismatchError:
      'El precio para el horario seleccionado ha cambiado. Por favor, actualiza la página e inténtalo de nuevo.',
    NotAvailable: 'No disponible',
    DepartureTimesNotAvailable:
      'Estos horarios de salida no están disponibles con la duración seleccionada:',
    NotAvailableForOnlineBooking: 'No disponibles para reservas online',
    PersonalDetailsInvalid: 'Los detalles personales no son válidos.',
    Alcoholerror: 'La quantità di alcol supera il limite consentito.',
    WarrantyWasRemoved1:
      'La garantía de cancelación se ha eliminado porque su hora de salida actual es dentro de menos de',
    WarrantyWasRemoved2: 'horas.',
    WarrantyCannotBeAdded1:
      'La garantía de cancelación no se puede añadir si la hora de salida es dentro de menos de',
    WarrantyCannotBeAdded2: 'horas.',
    WarrantyMissingDateAndTime:
      'Seleccione una fecha y una hora de salida antes de añadir la garantía de cancelación.',
    WarrantyInvalidDateAndTime:
      'La garantía de cancelación no puede añadirse a la fecha y hora de salida seleccionadas.',
  },

  /**
   * FAQ
   */
  faq: {
    title: 'Cómo comprar una tarjeta de regalo',
    p1: 'La tarjeta de regalo y el código único se enviarán a tu bandeja de entrada con instrucciones sobre cómo utilizarlo. Por favor, guarda este correo electrónico en un lugar seguro, ya que es tu comprobante de compra. La tarjeta de regalo se puede canjear por el valor monetario de tu compra actual dentro de',
    p2: 'meses para el alquiler de barcos en la ubicación que has seleccionado. No se puede canjear por efectivo.',
    campaign:
      'La tarjeta de regalo y el código único se enviarán a tu bandeja de entrada con instrucciones sobre cómo utilizarlo. Por favor, guarda este correo electrónico en un lugar seguro, ya que es tu comprobante de compra. La tarjeta de regalo se puede canjear por el valor monetario de tu compra actual para el alquiler de barcos en la ubicación que has seleccionado. Consulta la fecha de vencimiento en la tarjeta de regalo. No se puede canjear por efectivo.',
    show: 'Mostrar preguntas frecuentes',
    hide: 'Ocultar preguntas frecuentes',
  },

  /**
   * Meta
   */
  factBox: {
    close: 'Cerrar',
    seeMore: 'Ver más',
  },
  meta: {
    description:
      'Alquila un barco en GoBoat y disfruta de una experiencia única en el agua. Los barcos pueden llevar hasta ocho personas y no se requiere licencia ni experiencia para operarlos.',
  },

  faqList: [
    'Elige la duración del alquiler para la tarjeta de regalo',
    'Ingresa tu dirección de correo electrónico (debe ser tu dirección de correo electrónico, no la de la persona a la que le darás la tarjeta)',
    'Ingresa los detalles de tu tarjeta de crédito y confirma',
  ],

  cancellationReason: {
    [CustomerFacingCancellationReason.WEATHER]: 'Clima',
    [CustomerFacingCancellationReason.SCHEDULE]: 'Cambio de planes',
    [CustomerFacingCancellationReason.SICKNESS]: 'Enfermedad',
    [CustomerFacingCancellationReason.OTHER]: 'Otro',
  },
};

/* eslint-disable max-lines */

import { CustomerFacingCancellationReason, SingleLanguageLocalization } from './types';

/**
 * ---------- GERMAN ---------------
 *| |_______________________________
 *| ||                              |
 *| ||                              |
 *| ||                              |
 *| ||MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM|
 *| ||MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM|
 *| ||MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM|
 *| ||IIIIIIIIIIIIIIIIIIIIIIIIIIIIII|
 *| ||IIIIIIIIIIIIIIIIIIIIIIIIIIIIII|
 *| ||IIIIIIIIIIIIIIIIIIIIIIIIIIIIII|
 *| |'""""""""""""""""""""""""""""""'
 */
export const de: SingleLanguageLocalization = {
  /**
   * Product
   */
  product: {
    location: 'Standort',
    noLocationSelected: 'Kein Standort ausgewählt',
    invalidBookingId: 'Buchung nicht gefunden - ungültige Buchungsnummer',
    confirm: 'bestätigen',
    Booking: 'Buchung',
    booking: 'Buchung',
    yourBooking: 'Deine Buchung',
    Giftcard: 'Geschenkkarte',
    giftcard: 'Geschenkkarte',
    campaign: 'Aktion',
    voucher: 'Gutschein',
    refund: 'Gutschein',
    membership: 'Mitgliedschaft',
    'membership-campaign': 'Aktion',
    yourGiftcard: 'Deine Geschenkkarte',
    configHeader: 'Boote & Dauer',
    durationHeading: 'Boote & Dauer',
    dateAndTime: 'Datum & Abfahrtszeit',
    yourInformation: 'Deine Information',
    extras: 'Extras',
    extrasAndRules: 'Extras & Regeln',
    finalize: 'Bestellung abschließen',
    completeBooking: `Deine Buchung ist fast abgeschlossen.

      Schließe die Buchung ab, indem Du auf die Schaltfläche rechts klickst.`,
    completeBookingMobile: `Deine Buchung ist fast abgeschlossen.

      Schließe die Buchung über die Bestellübersicht am unteren Bildschirmrand ab.`,
    numberOfUnits: 'Anzahl Boote',
    personsPerUnit: '8 Pers. pro Boot',
    boat: 'Boot',
    boats: 'Boote',
    person: 'Person',
    people: 'Personen',
    hour: 'Stunde',
    hours: 'Stunden',
    minutes: 'Minuten',
    hourAbbr: 'h',
    minuteAbbr: 'm',
    chooseLocation: 'Wähle einen Standort',
    currentBooking: 'Aktuelle Buchung',
    show: 'Anzeigen',
    hide: 'Ausblenden',
    currentbooking: 'Aktuelle Buchung',
    currentgiftcard: 'Aktuelle Geschenkkarte',
    configureGiftcard: 'Konfiguriere Deine Geschenkkarte',
    durationInHours: 'Dauer',
    acceptTerms: 'Bestätige unsere Allgemeinen Geschäftsbedingungen',
    subscribeToNewsletter:
      'Ich möchte den Newsletter von GoBoat mit maritimen Inspirationen und Infos zu Veranstaltungen erhalten.',
    yes: 'Ja',
    no: 'Nein',
    subscribeToNewsletterShort: 'Newsletter abonnieren',
    next: 'Weiter',
    prev: 'Zurück',
    page: 'Seite',
    addToOrder: 'Zur Bestellung hinzufügen',
    added: 'Hinzugefügt',
    haveAVoucher: 'Hast du einen Gutschein?',
    voucherCode: 'Gutscheincode',
    applyVoucher: 'Gutschein verwenden',
    voucherApplied: 'Gutschein wurde verwendet',
    toBePaid: 'Zu zahlen',
    confirmPayment: 'Zahlung bestätigen',
    confirmBooking: 'Buchung bestätigen',
    confirmGiftcard: 'Geschenkkarte bestätigen',
    confirmMembership: 'Mitgliedschaft bestätigen',
    cancelBooking: 'Buchung stornieren',
    canceledBooking: 'Es tut uns leid, Sie gehen zu sehen!',
    canceledButtonTextInitial: 'Neue Erfahrung buchen',
    cancellationFeedbackPrompt:
      'Bitte lassen Sie uns den Grund für Ihre Stornierung wissen, indem Sie auf eine der unten stehenden Optionen klicken:',
    cancellationThankYou:
      'Vielen Dank für Ihr Feedback. Wir schätzen Ihre Zeit und hoffen, Sie in Zukunft besser bedienen zu können.',
    canceledTextInitial:
      'Die Buchung ist storniert, klicken Sie auf die Schaltfläche unten, wenn Sie eine neue Erfahrung buchen möchten.',
    canceledTextNoRefund:
      'Wir verstehen, dass sich Pläne ändern können, und würden gerne wissen, warum Sie Ihre Buchung bei uns stornieren mussten. Ihr Feedback ist wertvoll und hilft uns, unseren Service zu verbessern.',

    canceledTextVoucherRefund: `Ein Gutschein wurde ausgestellt und wird Ihnen in Kürze per E-Mail zugesandt.

Wir verstehen, dass sich Pläne ändern können, und würden gerne wissen, warum Sie Ihre Buchung bei uns stornieren mussten. Ihr Feedback ist wertvoll und hilft uns, unseren Service zu verbessern.`,

    cancelTextNoRefund1:
      'Sind Sie sicher, dass Sie Ihre Buchung stornieren möchten? Da die Zeit bis zur Ausfahrt weniger als ',
    cancelTextNoRefund2:
      ' Stunden beträgt oder keine Zahlung für die Buchung eingegangen ist, wird Ihre Buchung storniert und Sie erhalten keine Rückerstattung.',
    cancelTextRefund1:
      'Wenn Sie eine Rückerstattung wünschen, müssen Sie den Kundendienst kontaktieren.',
    cancelTextRefund2: 'Da die Zeit bis zur Ausfahrt weniger als ',
    cancelTextRefund3: ' Stunden beträgt, können Sie keine Rückerstattung erhalten.',
    cancelTextVoucherRefund1:
      'Sind Sie sicher, dass Sie Ihre Buchung stornieren möchten? Sie erhalten eine E-Mail mit einem Gutschein im Wert von ',
    cancelTextVoucherRefund2: 'Der Gutschein läuft in ',
    cancelTextVoucherRefund3: ' Monaten ab.',
    pleaseWait: 'Bitte warten...',
    positiveBalance1: 'Ihre Buchung weist einen positiven Saldo auf.',
    positiveBalance2Refund:
      'Sie können dieses Guthaben verwenden, um Extras zu kaufen, oder Sie können eine Rückerstattung beantragen, indem Sie sich an den Kundendienst wenden.',
    positiveBalance2VoucherRefund:
      'Sie können dieses Guthaben verwenden, um Extras zu kaufen, oder Sie können einen Gutschein anfordern, indem Sie sich an den Kundendienst wenden.',
    positiveBalance3:
      'Wenn Du eine Rückerstattung im Voraus möchtest, schreibe uns eine E-Mail an ',
    neutralBalance:
      'Deine Bestellung ist ausgeglichen - keine zusätzliche Zahlung erforderlich.',
    refundEmailSubject: 'Erstattung - Buchung ID: ',
    priceForSailing: 'Preis für das Boot',
    priceForExtras: 'Preis für Extras',
    priceForBooking: 'Preis für die Buchung',
    cancellationWarranty: 'Stornogarantie',
    cancellationWarrantyHeading: 'Benötigen Sie zusätzliche Sicherheit?',
    cancellationWarrantyText1:
      'Indem Sie unsere Stornierungsgarantie hinzufügen, können Sie Ihre Buchung bis zu ',
    cancellationWarrantyText2: ' Stunden vor dem Auslaufen umbuchen oder stornieren.',
    thankYou: 'Dankeschön',
    captain: 'Kapitän',
    bookingCompleted:
      'Vielen Dank für Deine Reservierung. Wir freuen uns, Dich an unserem Hafen begrüßen zu dürfen.',
    practicalInfo1: 'Bitte denke daran ',
    practicalInfo2: ' 15 min. vor der Abfahrtszeit zu erscheinen.',
    confirmationEmailSentTo: 'Eine Bestätigungs-E-Mail wurde gesendet an',
    learnMore: 'Mehr erfahren',
    cancel: 'Abbrechen',
    fetching: 'Seite lädt',
    loading: 'Seite lädt',
    selectDateToViewTimeslots:
      'Wähle ein Datum, um die verfügbaren Zeitfenster zu erhalten',
    yourInformationBookingHeld:
      'Sobald Sie Ihre Informationen ausgefüllt haben, wird Ihre Abfahrtszeit für 10 Minuten reserviert.',
    noOpenDays: 'Es gibt keine offenen Tage im ausgewählten Monat',
    defaultPaymentSuccessText: `Vielen Dank für Deine Reservierung. Wir freuen uns, Dich an unserem Hafen begrüßen zu dürfen.

Bitte denke daran, 15 min. vor der Abfahrtszeit zu erscheinen.

Solltest Du Fragen zur Buchung haben, kannst Du uns gerne kontaktieren.`,
    OR: 'ODDER',
    left: 'übrig',
    goToCheckout: 'Zur Kasse gehen',
    bookingHeldHeading: 'Ihre Abfahrtszeit ist reserviert',
    bookingHeldText:
      'Nach 10 Minuten können wir nicht garantieren, dass Ihre Abfahrtszeit noch verfügbar ist.',
    bookingHeldTextConfirmBooking: 'Bitte fahren Sie fort, um die Buchung zu bestätigen.',
    allRightsReserved: 'Alle Rechte vorbehalten',
    cookieSettings: 'Cookie-Einstellungen',
    custom: 'Benutzerdefiniert',
    amount: 'Betrag',
    customAmount: 'Benutzerdefinierter Betrag',
    selectPickUpLocation: 'Wählen Sie, wo Sie einsteigen möchten',
    selectDropOffLocation: 'Wählen Sie, wo Sie aussteigen möchten',
    select: 'Wählen',
    selected: 'Ausgewählt',
    includedInPrice: 'Im Preis inbegriffen',
    viewOnMap: 'Auf der Karte anzeigen',
    pickupLocation: 'Abholort',
    dropoffLocation: 'Abgabeort',
    from: 'Bei',
    enterYourPaymentDetails: 'Geben Sie Ihre Zahlungsdaten ein',
    roomForUpTo: 'Platz für bis zu',
    addDesiredExtras: 'Fügen Sie Ihre gewünschten Extras unten hinzu.',
    whereWouldYouLikeToSail: 'Wohin möchten Sie gerne segeln?',
    howManyBoats: 'Wie viele Boote?',
    howManyHours: 'Wie viele Stunden?',
    customerFormHeading: 'Auf welchen Namen dürfen wir Ihre Buchung bestätigen?',
    customerFormText:
      'E-Mail ist für die Bestätigung, Telefonnummer ist für die Sicherheit.',
  },

  /**
   * Giftcard
   */
  giftcard: {
    recipientInformation: 'Informationen für den Empfänger',
    optional: 'Wahlweise',
    defaultPaymentSuccessText:
      'Vielen Dank, dass Du einen Geschenkkarte für ein einzigartiges und nachhaltiges Erlebnis bei GoBoat gekauft hast.',
  },

  /**
   * Membership
   */
  membership: {
    membership: 'Mitgliedschaft',
    yourMembership: 'Deine Mitgliedschaft',
    defaultPaymentSuccessText:
      'Vielen Dank, dass Du dich für eine GoBoat Plus-Mitgliedschaft angemeldet hast',
  },

  /**
   * Sauna
   */
  sauna: {
    duration: 'Dauer',
    dateAndTime: 'Datum & Uhrzeit',
    selectDateToViewTimeslots: 'Datum auswählen, um verfügbare Zeitfenster zu erhalten',
    defaultPaymentSuccessText: `Vielen Dank für Ihre Reservierung. Wir freuen uns darauf sie begrüßen zu dürfen.

Bitte denken daran, 15 Minuten vor Ihrem Zeitfenster zu erscheinen.

Solltest Du Fragen zur Buchung haben, kannst Du uns gerne kontaktieren.`,
    boat: 'person',
    boats: 'personen',
    numberOfUnits: 'Anzahl personen',
    personsPerUnit: 'Max 8 personen',
    durationHeading: 'Anzahl & dauer',
  },

  /**
   * Customer
   */
  customer: {
    firstname: 'Vorname',
    lastname: 'Nachname',
    phone: 'Telefonnummer',
    email: 'E-Mail Adresse',
    birthDate: 'Geburtsdatum',
    birthDatePattern: '(JJJJ-MM-TT)',
    zip: 'Postleitzahl',
    province: 'Provinz',
    streetAddress: 'Adresse',
    city: 'Stadt',
    pin: 'Italian Fiscal Code',
  },

  /**
   * Recipient
   */
  recipient: {
    name: 'Name',
  },

  /**
   * Price
   */
  price: {
    price: 'Preis',
    allIncluded: 'Gesamtpreis',
    paid: 'Bezahlt',
    toBePaid: 'Zu zahlen',
    toBeRefunded: 'Wird zurückerstattet',
    confirm: 'Confirm',
  },

  /**
   * Lookup
   */
  lookup: {
    noBookingTitle: 'Hmm wir können diese Buchung nicht finden...',
    noBookingContent: 'Wir scheinen keine Buchung mit der ID im System zu haben:',
    noRequestTitle: 'Suchst Du nach Deiner Buchung?',
    noRequestContent: 'Es sieht nicht so aus, als hättest Du eine Buchung angefragt.',
    giefId:
      'Bitte gib die Buchungs-ID aus der Bestätigungs-E-Mail an, die Du von uns erhalten hast.',
    getBooking: 'Buchung abrufen',
  },

  /**
   * Error
   */
  error: {
    NoShopId: 'Die Shop-ID ist nicht gültig.',
    NoBoatCount: 'Die Buchung enthält keine Boote.',
    NoAvailableTimeslots: 'Es sind keine Zeitfenster verfügbar.',
    SlotNoLongerAvailable:
      'Das ausgewählte Zeitfenster ist nicht verfügbar. Bitte wähle ein anderes Zeitfenster.',
    BookingNotCreated:
      'Es ist ein Fehler aufgetreten. Die Buchung wurde nicht erstellt. Bitte aktualisiere die Seite und versuche es erneut.',
    SelectStartTime: 'Bitte wähle eine Startzeit für Deine Buchung.',
    WeekdayNotValid: 'Der ausgewählte Wochentag ist für diesen Gutschein nicht geeignet.',
    VoucherAlreadyAdded: 'Es wurde bereits ein Gutschein zu dieser Buchung hinzugefügt.',
    VoucherNotValidForTimeslot:
      'Der Gutschein ist für die gewählte Abfahrtszeit nicht gültig.',
    PercentVoucherAlreadyUsed:
      'Es wurde bereits ein Gutschein zu dieser Buchung hinzugefügt.',
    VoucherDepleted: 'Der Gutschein ist nicht mehr gültig.',
    VoucherLocationInvalid: 'Der Gutschein ist für den gewählten Ort nicht gültig.',
    VoucherWeekdayInvalid: 'Der Gutschein ist für den gewählten Wochentag nicht gültig.',
    VoucherDateInvalid: 'Der Gutschein ist für das gewählte Datum nicht gültig.',
    VoucherValidForInvalid: 'Der Gutschein ist nicht gültig.',
    VoucherInvalid: 'Der Gutschein ist nicht gültig.',
    VoucherNotFound: 'Der Gutschein wurde nicht gefunden.',
    InvalidVoucherCombination: 'Diese Gutscheine können nicht zusammen verwendet werden.',
    VoucherAlreadyApplied: 'Der Gutschein wurde bereits auf die Buchung angewendet.',
    NotValidPayment:
      'Die Zahlungsanforderung ist nicht gültig - die Transaktion wurde nicht abgeschlossen.',
    ShopNotOpen: 'Der Shop hat an dem gewählten Datum nicht geöffnet.',
    ShopNoLongerOpen:
      'Der Shop hat an dem gewählten Datum keine gültigen Öffnungszeiten mehr.',
    ShopHasNoPriceModels: 'Der Shop hat keine Preismodelle.',
    NoShopsFound: 'Keine Standorte gefunden',
    UnknownError:
      'Ein unbekannter Fehler ist aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
    PriceMismatchError:
      'Der Preis für Ihren ausgewählten Zeitraum hat sich geändert. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
    NotAvailable: 'Nicht verfügbar',
    DepartureTimesNotAvailable:
      'Diese Abfahrtszeiten sind für die ausgewählte Dauer nicht verfügbar:',
    NotAvailableForOnlineBooking:
      'Abfahrtszeiten sind für Online-Buchungen nicht verfügbar',
    PersonalDetailsInvalid: 'Die eingegebenen persönlichen Daten sind ungültig.',
    Alcoholerror: 'Die Menge an Alkohol überschreitet die erlaubte Grenze.',
    WarrantyWasRemoved1:
      'Der Stornogarantie wurde aufgehoben, weil Ihre aktuelle Abfahrtszeit weniger als',
    WarrantyWasRemoved2: 'Stunden beträgt.',
    WarrantyCannotBeAdded1:
      'Der Stornogarantie kann nicht hinzugefügt werden, wenn die Abfahrtszeit weniger als',
    WarrantyCannotBeAdded2: 'Stunden beträgt.',
    WarrantyMissingDateAndTime:
      'Bitte wählen Sie ein Datum und eine Abfahrtszeit, bevor Sie den Stornogarantie hinzufügen.',
    WarrantyInvalidDateAndTime:
      'Der Stornogarantie kann nicht mit dem gewählten Datum und der Abfahrtszeit kombiniert werden.',
  },

  /**
   * FAQ
   */
  faq: {
    title: 'So kaufst Du einen Geschenkkarte',
    p1: 'Die Geschenkkarte und der einmalige Code werden an Deine E-Mail Adresse gesendet, zusammen mit einer Anleitung, wie Du sie verwenden kannst. Bitte bewahre diese E-Mail sicher auf, da sie Dein Kaufbeleg ist. Die Geschenkkarte kann für den Geldwert Deines heutigen Einkaufs innerhalb von',
    p2: 'Monate in denen die Bootsvermietung offen hat an dem von Dir gewählten Standort. Die Geschenkkarte kann nicht gegen Bargeld eingelöst werden.',
    campaign:
      'Die Geschenkkarte und der einmalige Code werden an Deine E-Mail Adresse gesendet, zusammen mit einer Anleitung, wie Du sie verwenden kannst. Bitte bewahre diese E-Mail sicher auf, da sie Dein Kaufbeleg ist. Die Geschenkkarte kann für den Geldwert Ihres heutigen Einkaufs für die Bootsmiete an dem von Ihnen ausgewählten Ort eingelöst werden. Siehe Ablaufdatum auf der Geschenkkarte. Die Geschenkkarte kann nicht gegen Bargeld eingelöst werden.',
    show: 'FAQs anzeigen',
    hide: 'FAQs ausblenden',
  },

  factBox: {
    close: 'schließen',
    seeMore: 'Zeig mehr',
  },

  /**
   * Meta
   */
  meta: {
    description:
      'Miete ein Boot bei GoBoat und erleb ein einzigartiges Erlebnis auf dem Wasser. Die Boote können bis zu acht Personen befördern und es ist weder ein Führerschein noch Erfahrung erforderlich, um das Boot zu bedienen.',
  },

  faqList: [
    'Wähle die Mietdauer für den Geschenkkarte',
    'Gib bitte Deine E-Mail-Adresse ein (dies sollte Deine E-Mail-Adresse sein, nicht die der Person, der Du die Karte schenkst)',
    'Gib Deine Kreditkartendaten ein und bestätige',
  ],
  cancellationReason: {
    [CustomerFacingCancellationReason.WEATHER]: 'Wetter',
    [CustomerFacingCancellationReason.SCHEDULE]: 'Planänderung',
    [CustomerFacingCancellationReason.SICKNESS]: 'Krankheit',
    [CustomerFacingCancellationReason.OTHER]: 'Sonstiges',
  },
};
